export default [
    {name: 'Max Verstappen', id: 'ver', team: 'Red Bull', number: '1', country: 'Netherlands', points: 0, photo: 'max2.jpg', birthdate: '1997/09/30', champ: '2021, 2022, 2023, 2024', link: 'https://en.wikipedia.org/wiki/Max_Verstappen'},
    {name: 'Sergio Perez', id: 'per', team: 'Red Bull', number: '11', country: 'Mexico', points: 0, photo: 'checo2.jpg', birthdate: '1990/01/26', link: 'https://en.wikipedia.org/wiki/Sergio_P%C3%A9rez'},
    {name: 'Lewis Hamilton', id: 'ham', team: 'Mercedes', number: '44', country: 'Great Britain', points: 0, photo: 'lewis.jpg', birthdate: '1985/01/07', champ: '2008, 2014, 2015, 2017, 2018, 2019, 2020', link: 'https://en.wikipedia.org/wiki/Lewis_Hamilton'},
    {name: 'Charles Leclerc', id: 'lec', team: 'Ferrari', number: '16', country: 'Monaco', points: 0, photo: 'charles2.jpg', birthdate: '1997/10/16', link: 'https://en.wikipedia.org/wiki/Charles_Leclerc'},
    {name: 'Fernando Alonso', id: 'alo', team: 'Aston Martin', number: '14', country: 'Spain', points: 0, photo: 'fernando2.jpg', birthdate: '1981/07/29', champ: '2005, 2006', link: 'https://en.wikipedia.org/wiki/Fernando_Alonso'},
    {name: 'Lando Norris', id: 'nor', team: 'McLaren', number: '4', country: 'Great Britain', points: 0, photo: 'lando2.jpg', birthdate: '1999/11/13', link: 'https://en.wikipedia.org/wiki/Lando_Norris'},
    {name: 'Carlos Sainz', id: 'sai', team: 'Ferrari', number: '55', country: 'Spain', points: 0, photo: 'carlos2.jpg', birthdate: '1994/09/01', link: 'https://en.wikipedia.org/wiki/Carlos_Sainz_Jr.'},
    {name: 'George Russell', id: 'rus', team: 'Mercedes', number: '63', country: 'Great Britain', points: 0, photo: 'george2.jpg', birthdate: '1998/02/15', link: 'https://en.wikipedia.org/wiki/George_Russell_(racing_driver)'},
    {name: 'Oscar Piastri', id: 'pia', team: 'McLaren', number: '81', country: 'Australia', points: 0, photo: 'oscar.jpg', birthdate: '2001/04/06', link: 'https://en.wikipedia.org/wiki/Oscar_Piastri'},
    {name: 'Lance Stroll', id: 'str', team: 'Aston Martin', number: '18', country: 'Canada', points: 0, photo: 'lance.jpg', birthdate: '1998/10/29', link: 'https://en.wikipedia.org/wiki/Lance_Stroll'},
    {name: 'Pierre Gasly', id: 'gas', team: 'Alpine', number: '10', country: 'France', points: 0, photo: 'pierre2.jpg', birthdate: '1996/02/07', link: 'https://en.wikipedia.org/wiki/Pierre_Gasly'},
    {name: 'Esteban Ocon', id: 'oco', team: 'Alpine', number: '31', country: 'France', points: 0, photo: 'esteban2.webp', birthdate: '1996/09/17', link: 'https://en.wikipedia.org/wiki/Esteban_Ocon'},
    {name: 'Alex Albon', id: 'alb', team: 'Williams', number: '23', country: 'Thailand', points: 0, photo: 'alex2.jpg', birthdate: '1996/03/23', link: 'https://en.wikipedia.org/wiki/Alex_Albon'},
    {name: 'Yuki Tsunoda', id: 'tsu', team: 'AlphaTauri', number: '22', country: 'Japan', points: 0, photo: 'yuki.jpg', birthdate: '2000/05/11', link: 'https://en.wikipedia.org/wiki/Yuki_Tsunoda'},
    {name: 'Valtteri Bottas', id: 'bot', team: 'Alfa Romeo', number: '77', country: 'Finland', points: 0, photo: 'valtteri2.jpg', birthdate: '1989/08/28', link: 'https://en.wikipedia.org/wiki/Valtteri_Bottas'},
    {name: 'Nico Hülkenberg', id: 'hul', team: 'Haas', number: '27', country: 'Germany', points: 0, photo: 'nico2.jpg', birthdate: '1987/08/19', link: 'https://en.wikipedia.org/wiki/Nico_H%C3%BClkenberg'},
    {name: 'Daniel Ricciardo', id: 'ric', team: 'McLaren', number: '3', country: 'Australia', points: 0, photo: 'daniel2.jpg', birthdate: '1989/07/01', link: 'https://en.wikipedia.org/wiki/Daniel_Ricciardo'},
    {name: 'Guanyu Zhou', id: 'zho', team: 'Alfa Romeo', number: '24', country: 'China', points: 0, photo: 'guanyu2.jpg', birthdate: '1999/05/30', link: 'https://en.wikipedia.org/wiki/Guanyu_Zhou'},
    {name: 'Kevin Magnussen', id: 'mag', team: 'Haas', number: '20', country: 'Denmark', points: 0, photo: 'kevin.webp', birthdate: '1992/10/05', link: 'https://en.wikipedia.org/wiki/Kevin_Magnussen'},
    {name: 'Logan Sargeant', id: 'sar', team: 'Williams', number: '2', country: 'United States', points: 0, photo: 'logan2.jpg', birthdate: '2000/12/31', link: 'https://en.wikipedia.org/wiki/Logan_Sargeant'},
    {name: 'Sebastian Vettel', id: 'vet', team: 'Aston Martin', number: '5', country: 'Germany', points: 0, photo: 'seb.jpg', birthdate: '1987/07/03', champ: '2010, 2011, 2012, 2013', link: 'https://en.wikipedia.org/wiki/Sebastian_Vettel', note: 'Retired', out: true},
    {name: 'Mick Schumacher', id: 'msc', team: 'Haas', number: '19', country: 'Germany', points: 0, photo: 'mick.jpg', birthdate: '1999/03/22', link: 'https://en.wikipedia.org/wiki/Mick_Schumacher', out: true},
    {name: 'Nicholas Latifi', id: 'lat', team: 'Williams', number: '6', country: 'Canada', points: 0, photo: 'nicholas.jpg', birthdate: '1995/06/29', link: 'https://en.wikipedia.org/wiki/Nicholas_Latifi', out: true},
    {name: 'Nyck de Vries', id: 'dev', team: 'AlphaTauri', number: '45', country: 'Netherlands', points: 0, photo: 'nyck2.jpg', birthdate: '1995/02/06', link: 'https://en.wikipedia.org/wiki/Nyck_de_Vries', note: 'out since Hungarian GP'},
    {name: 'Liam Lawson', id: 'law', team: 'AlphaTauri', number: '15', country: 'New Zealand', points: 0, photo: 'liam.jpg', birthdate: '2002/02/11', link: 'https://en.wikipedia.org/wiki/Liam_Lawson', note: 'Replace Daniel due to his injury since Netherlands GP'},
    {name: 'Nikita Mazepin', id: 'maz', team: 'Haas', number: '9', country: 'Russia', points: 0, photo: 'nikita.jpg', birthdate: '1992/03/02', link: 'https://en.wikipedia.org/wiki/Nikita_Mazepin'},
    {name: 'Kimi Raikkonen', id: 'rai', team: 'Alfa Romeo', number: '7', country: 'Finland', points: 0, photo: 'kimi.jpg', note: 'Retired', out: true, birthdate: '1979/10/17', champ: '2007', link: 'https://en.wikipedia.org/wiki/Kimi_R%C3%A4ikk%C3%B6nen'},
    {name: 'Antonio Giovinazzi', id: 'gio', team: 'Alfa Romeo', number: '99', country: 'Italy', points: 0, photo: 'antonio.jpg', note: '2022 Formula E', out: true, birthdate: '1993/12/14', link: 'https://en.wikipedia.org/wiki/Antonio_Giovinazzi'},
    {name: 'Oliver Bearman', id: 'bea', team: 'Ferrari', number: '38', country: 'Great Britain', points: 0, photo: 'oliver.jpg', birthdate: '2005/05/08', link: 'https://en.wikipedia.org/wiki/Oliver_Bearman', note: 'Replace Carlos due to his appendicitis on Saudi Arabia GP'},
    {name: 'Oliver Bearman', id: 'bea__haas', team: 'Haas', number: '38', country: 'Great Britain', points: 0, photo: 'oliver.jpg', birthdate: '2005/05/08', link: 'https://en.wikipedia.org/wiki/Oliver_Bearman', note: 'Replace Carlos due to his appendicitis on Saudi Arabia GP'},
    {name: 'Franco Colapinto', id: 'col', team: 'Williams', number: '43', country: 'Argentina', points: 0, photo: 'franco.jpg', birthdate: '2003/05/27', link: 'https://en.wikipedia.org/wiki/Franco_Colapinto', note: 'Replace Logan since Monza #16'},
]
